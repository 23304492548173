<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            helpers.monthName,
            { key: 'month_id', _classes: 'hide' },
            helpers.building,
            getHelperObject('building_type', 'building.building_type_id'),
            getHelperObject('property_unit', 'building.property_unit_id'),
            helpers.ownerName,
            { ...this.helpers.currencyValue, key: 'previous_month_balance' },
            { ...this.helpers.currencyValue, key: 'previous_month_payment' },
            { key: 'consecutive', label: '# Current invoice', helper: { id: 'invoice.consecutive' } },
            ...dynamic_fields
          ]"
          :config="{
            name: 'invoice',
            url: 'admin/reports/building_balance_detailed',
            route: '/admin/reports/building_balance_detailed',
            display_name: 'Cartera x inmueble' + (is_summary ? ' - Resumido' : ''),
            params: { _lists: 'months,building_types,property_units' },
            filters: [
              { key: 'month_id', multiple: false, default: 'response.data.month_id', forced: true, list: 'months', callback: this.onGetData }, // TODO: Multiple
              { key: 'building_code', use: 'building.identifier', multiple: false }
            ],
            noCreate: true,
            noOptions: 'hide',
            parseItems
          }"
          :sorterValue="{ column: 'consecutive', asc: false }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'BuildingBalanceDetailed',
  mixins: [crudMixin],
  data() {
    return {
      month: {},
      months: {},
      building_id: 0,
      dynamic_fields: [],
      is_summary: this.$route.meta.type === 'summary'
    }
  },
  methods: {
    onGetData(month_id) {
      if (month_id) {
        this.month = { month_id, _period_name: this.getPeriodName(month_id) }
        this.$refs.crud_table.getData(false, { month_id }, true)
      }
    },

    parseItems(parent, items) {
      this.$set(this.$refs.crud_table.options, 'file_name', this.property.name + ' - ' + 'Rep Cartera x Inmueble' + (this.is_summary ? ' Resumido - ' : ' - ') + this.getPeriodName(this.$refs.crud_table.response.data.month_id))

      this.dynamic_fields = []
      const dynamic_fields = {
        overdue_values: {},
        overdue_notes: {},
        current_values: {},
        current_notes: {}
      }
      for (let item in items) {
        item = items[item]
        item.db_notes = 0
        item.cr_notes = 0
        item.admon_value = 0
        item.overdue_capital = 0

        for (const overdue_value of item.overdue_values) {
          dynamic_fields.overdue_values[overdue_value.name] = overdue_value.name
          item.overdue_capital += overdue_value.value
          item['(V) ' + overdue_value.name] = overdue_value.value
        }
        for (const overdue_note of item.overdue_notes) {
          dynamic_fields.overdue_notes[overdue_note.name] = overdue_note.name
          if (overdue_note.nature_id === 'DB') item.db_notes += overdue_note.value
          else item.cr_notes += overdue_note.value
          item['(NV) ' + overdue_note.name] = overdue_note.value
        }
        for (const current_value of item.current_values) {
          // TODO: admon imputation classes as Params?
          if (this.is_summary && ['AAP', 'APQ', 'ACU'].indexOf(current_value.imputation_class_id) >= 0) {
            item.admon_value += current_value.value
          } else {
            dynamic_fields.current_values[current_value.name] = current_value.name
          }
          item[current_value.name] = current_value.value
        }
        for (const current_note of item.current_notes) {
          dynamic_fields.current_notes[current_note.name] = current_note.name
          if (current_note.nature_id === 'DB') item.db_notes += current_note.value
          else item.cr_notes += current_note.value
          item['(N) ' + current_note.name] = current_note.value
        }
      }

      // Dynamic Fields
      if (!this.is_summary) {
        this.applyOrdering(dynamic_fields.overdue_values, '(V) ', 'background-color: #1769ff;') // TODO: use variables
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'overdue_interest', _style: 'background-color: #1769ff;' })
        this.applyOrdering(dynamic_fields.overdue_notes, '(NV) ', 'background-color: #3c81ff;')
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'generated_interest', _style: 'background-color: #45a164;' })
        this.dynamic_fields.push({ key: 'interest', _classes: 'text-right', formatter: 'numeric', render: value => this.parsers.numeric(value) + ' %', label: '% Interest', _style: 'background-color: #45a164;' })
        this.applyOrdering(dynamic_fields.current_values, '', 'background-color: #45a164;')
        this.applyOrdering(dynamic_fields.current_notes, '(N) ', 'background-color: #74a383;')
      } else {
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'overdue_capital', _style: 'background-color: #1769ff;' })
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'overdue_interest', _style: 'background-color: #1769ff;' })
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'generated_interest', _style: 'background-color: #45a164;' })
        this.dynamic_fields.push({ key: 'interest', _classes: 'text-right', formatter: 'numeric', render: value => this.parsers.numeric(value) + ' %', label: '% Interest', _style: 'background-color: #45a164;' })
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'admon_value', _style: 'background-color: #45a164;' })
        this.applyOrdering(dynamic_fields.current_values, '', 'background-color: #45a164;')
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'credit_notes', _style: 'background-color: #45a164;' })
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'debit_notes', _style: 'background-color: #45a164;' })
      }
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'total_balance' })
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'current_month_payment' })
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'total_balance_final' })
      //this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'extra_fee_incoming' })
      if (!this.is_summary) {
        //this.dynamic_fields.push(this.getHelperObject('marking'))
        this.dynamic_fields.push({ key: 'debt_marking' })
        this.dynamic_fields.push({ key: 'apply_interest', label: 'Generate interests' })
      }
      this.dynamic_fields.push({ ...this.helpers.decimalValue, key: 'overdue', label: 'Overdue fees' })
      this.dynamic_fields.push({ key: 'overdue_months' })

      return items
    },

    applyOrdering(dynamic_fields, prefix, _style) {
      const dynamic_fields_array = Object.keys(dynamic_fields)
      for (const imputation of this.$refs.crud_table.response.data.imputation_order) {
        if (dynamic_fields_array.indexOf(imputation.name) >= 0) this.dynamic_fields.push({ ...this.helpers.currencyValue, key: (prefix || '') + imputation.name, label: (prefix || '') + imputation.name, _style }) // TODO: Capitalize ?
      }
    }
  }
}
</script>
